import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import HeroImage from 'components/new/hero/hero-image'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import Spacer from 'components/new/spacer'
import H1 from 'components/new/typography/h1'
import H2 from 'components/new/typography/h2'
import H5 from 'components/new/typography/h5'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import Checklist from 'components/new/checklist'
import Form from 'react-netlify-form'
import { Email } from 'components/forms/elements/v2/email'
import { FieldError } from 'components/forms/elements/v2/field-error'
import { Phone } from 'components/forms/elements/v2/phone'
import { Input } from 'components/forms/elements/v2/input'
import { Select } from 'components/forms/elements/v2/select'
import { Submit } from 'components/forms/elements/v2/submit'
import { useForm } from 'react-hook-form'
import { clearfix } from 'styles/clearfix'
import { column } from 'styles/column'
// import Youtube from 'components/youtube'
import { StickyContainer, Sticky } from 'react-sticky'

const Grid = styled.div`
  &:not(.thin) {
    @media ${props => props.theme.mediaQuery.mediumUp} {
      ${clearfix}

      .name-column {
        ${column('1/2')}
      }

      .email-column {
        ${column('1/2')}
      }

      .phone-column {
        ${column('1/2')}
      }
    }
  }
`

const ApplyForm = ({ thin, idSuffix }) => {
  const {
    register,
    errors,
    formState: { isDirty, isSubmitting, isValid },
    control,
  } = useForm({ mode: 'onBlur' })
  return (
    <Form
      name='High-Level Tech Campaign'
      id={`high-level-tech-campaign${idSuffix ? idSuffix : ''}`}
    >
      {({ loading, error, success }) => (
        <div>
          {success && (
            <React.Fragment>
              <ResponseMessage>
                <P>
                  <strong>We got your form.</strong> Thanks for your interest! We'll be in touch
                  soon to schedule a phone screening or you can click the link below to set up a
                  time.
                </P>
                <Button as={ButtonA} href='https://calendly.com/slattus-1' color='yellow'>
                  Schedule a call
                </Button>
              </ResponseMessage>
            </React.Fragment>
          )}
          {error && (
            <React.Fragment>
              <ResponseMessage error={true}>
                <P>
                  <strong>There was a problem processing your form.</strong> Please try again or
                  reach out to our recruiter at <a href='tel:270-226-7877'>(270) 226-7877</a>.
                </P>
              </ResponseMessage>
            </React.Fragment>
          )}
          {!success && (
            <div>
              <Grid className={thin ? 'thin' : ''}>
                <div className='name-column'>
                  <Input
                    label='First Name'
                    name='First Name'
                    id={`first-name${idSuffix ? idSuffix : ''}`}
                    register={register}
                    required
                  />
                  {errors['First Name'] ? <FieldError type={errors['First Name'].type} /> : null}
                </div>
                <div className='name-column'>
                  <Input
                    label='Last Name'
                    name='Last Name'
                    id={`last-name${idSuffix ? idSuffix : ''}`}
                    register={register}
                    required
                  />
                  {errors['Last Name'] ? <FieldError type={errors['Last Name'].type} /> : null}
                </div>
              </Grid>
              <Grid className={thin ? 'thin' : ''}>
                <div className='email-column'>
                  <Email register={register} id={`email${idSuffix ? idSuffix : ''}`} required />
                  {errors['Email'] ? <FieldError type={errors['Email'].type} /> : null}
                </div>
                <div className='phone-column'>
                  <Phone control={control} id={`phone${idSuffix ? idSuffix : ''}`} required />
                  {errors['Phone'] ? <FieldError type={errors['Phone'].type} /> : null}
                </div>
                <div className='location-column'>
                  <Select
                    name='Preferred Work Location'
                    label='Preferred Work Location'
                    placeholder='Select a location'
                    id={`preferred-work-location${idSuffix ? idSuffix : ''}`}
                    register={register}
                    required
                  >
                    <option value='Clinton, KY'>Clinton, KY</option>
                    <option value='Paducah, KY'>Paducah, KY</option>
                    <option value='Princeton, KY'>Princeton, KY</option>
                  </Select>
                </div>
              </Grid>
              <Spacer size='m' />
              <Submit
                disabled={(!isValid && isDirty) || success}
                loading={loading || isSubmitting}
                color='green'
                ghost
              />
            </div>
          )}
        </div>
      )}
    </Form>
  )
}

const ResponseMessage = styled.div`
  background-color: #fff;
  border-left: ${props => (props.error ? '3px solid hsl(0deg 100% 33.73%)' : '3px solid #377539')};
  margin: 24px 0;
  padding: 16px 24px;

  p {
    margin: 16px 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  a {
    margin-bottom: 4px;
  }
`

const ButtonA = styled.a`
  text-decoration: none;
`

const TechLandingPage = props => {
  const {
    data: { heroImage, heroImageMobile },
  } = props

  const heroImages = withArtDirection(getImage(heroImage), [
    {
      media: '(max-width: 599px)',
      image: getImage(heroImageMobile),
    },
  ])
  return (
    <Layout>
      <Helmet>
        <title>We're Hiring - Ag Equipment Mechanic | Hutson Inc</title>
        <meta name='robots' content='noindex' />
      </Helmet>

      <Hero image={heroImages}>
        <HeroContent kind='full'>
          <HeroCopy>
            <H1>Work with the best mechanics on the best equipment</H1>
            <P>We're looking for highly skilled mechanics with heavy machinery experience.</P>
            {/* <Spacer size='xl' /> */}
          </HeroCopy>
        </HeroContent>
        <HeroOverlay />
      </Hero>

      <TopContent kind='full'>
        <TopContentSidebarColumn>
          <TopContentSidebarStickyContainer>
            <Sticky>
              {({ style }) => (
                <TopContentSidebarStickyInner style={style}>
                  <HeroApplyBox>
                    <H2>Apply for an Ag Equipment Mechanic position</H2>
                    <Spacer size='xxs' />
                    {/* <P>Quickly see if you're a fit—no résumé required!</P> */}
                    <ApplyForm thin={true} idSuffix='-top' />
                  </HeroApplyBox>
                </TopContentSidebarStickyInner>
              )}
            </Sticky>
          </TopContentSidebarStickyContainer>
        </TopContentSidebarColumn>
        <TopContentMainColumn>
          <H2>About the job</H2>
          <P>
            Every day is a new challenge. You will be spending time in the best service trucks on
            the road and working at customer's farms. Our farmers will rely on you the help them
            keep their equipment running when they need it most. Our younger technicians will look
            up to you as a mentor and you will help accelerate their career. Technology should not
            scare you. John Deere and our other partners provide Hutson with state-of-the-art
            software and tools to diagnose and repair equipment.
          </P>
          <Spacer />
          <H2>Our ideal candidate</H2>
          <Spacer size='s' />
          <Checklist
            children={[
              'You know your stuff when it comes to mechanical, electrical, and hydraulic systems',
              'You have an, "I don\'t know, but I\'ll figure it out" kind of attitude',
              "You're detail oriented and take pride in your workspace",
              'You keep track of the work you do and complete the necessary paperwork that goes along with the job',
            ]}
          />
          <Spacer size='xs' />
          <P>
            <strong>Bonus points if:</strong>
          </P>
          <Checklist children={['You have worked at a John Deere dealership in the past']} />
          <Spacer />
          <H2>What you can expect from Hutson</H2>
          <BenefitsContainer>
            <h2>Benefits</h2>
            <ul>
              <li>Competitive wage paid bi-weekly</li>
              <li>401(k) plan with company match</li>
              <li>Healthcare (medical, dental, vision)</li>
              <li>
                Company-paid term life insurance plus Accidental Death &amp; Dismemberment
                (AD&amp;D)
              </li>
              <li>Company-paid short-term disability</li>
              <li>Health Savings Account (HSA) with company match</li>
              <li>Flexible Spending Account (FSA)</li>
              <li>Paid Time Off (PTO)</li>
              <li>Paid holidays</li>
              <li>Uniform allowance</li>
              <li>Employee referral bonus</li>
              <li>Employee discounts</li>
              <li>Dependent Care Assistance Plan</li>
              <li>Employee Assistance Program</li>
              <li>Wellness Program</li>
              <li>On-the-job training &amp; skills development</li>
            </ul>
          </BenefitsContainer>
        </TopContentMainColumn>
      </TopContent>
      {/* <DarkBackground>
        <VideoContent kind='full'>
          <Youtube videoId='u9wW9is1VOs' />
        </VideoContent>
      </DarkBackground> */}
      <Content kind='full'>
        <H2>Take the steps to a new career</H2>
        <Spacer size='m' />
        <StepsGrid>
          <StepsGridColumn>
            <StepsGridColumnLabel>Step 1</StepsGridColumnLabel>
            <H5 as='h3'>Fill out the form</H5>
            <P>
              This lets us know you're interested and gives us your contact information for the next
              step.
            </P>
          </StepsGridColumn>
          <StepsGridColumn>
            <StepsGridColumnLabel>Step 2</StepsGridColumnLabel>
            <H5 as='h3'>Schedule a phone screening</H5>
            <P>
              We'll set up a time to talk to see if you're a good fit. The call should only take
              about 30 minutes and is a great time to ask questions.
            </P>
          </StepsGridColumn>
          <StepsGridColumn>
            <StepsGridColumnLabel>Step 3</StepsGridColumnLabel>
            <H5 as='h3'>In person interview</H5>
            <P>
              After all phone screenings are conducted, we'll reach out to qualified candidates to
              set up an interview with the hiring manager.
            </P>
          </StepsGridColumn>
          <StepsGridColumn>
            <StepsGridColumnLabel>Step 4</StepsGridColumnLabel>
            <H5 as='h3'>Get the job offer</H5>
            <P>
              If you have what it takes, we'll send you a job offer. We'll plan a start date and set
              up an onboarding plan so you can hit the ground running.
            </P>
          </StepsGridColumn>
        </StepsGrid>
      </Content>
      <LightBackground>
        <Content kind='full'>
          <H2>What are you waiting for?</H2>
          <P>Let us know you're interested—no résumé required.</P>
          <ApplyForm idSuffix='-bottom' />
        </Content>
      </LightBackground>
    </Layout>
  )
}

const StepsGridColumn = styled.div``

const StepsGridColumnLabel = styled.div``

const StepsGrid = styled.div`
  ${clearfix}

  ${StepsGridColumn} {
    background-color: ${props => props.theme.color.n10};
    border: 1px solid ${props => props.theme.color.n30};
    border-top: 4px solid ${props => props.theme.color.g400};
    margin-bottom: 16px;
    padding: 16px;

    ${StepsGridColumnLabel} {
      margin-bottom: 8px;
    }
  }

  @media (min-width: 600px) {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;

    ${StepsGridColumn} {
      ${column('1/2', 16)}
    }
  }

  @media (min-width: 900px) {
    flex-wrap: nowrap;

    ${StepsGridColumn} {
      ${column('1/4', 16)}
      margin-bottom: 0;
    }
  }
`

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

// const DarkBackground = styled.div`
//   background-color: ${props => props.theme.color.n900};
// `

const Hero = styled(HeroImage)`
  height: 450px;

  @media (max-width: 599px) {
    h1 {
      font-size: 42px;
    }
  }

  @media (min-width: 600px) {
    h1 {
      font-size: 42px;
    }
  }

  @media (min-width: 900px) {
    height: 450px;
  }
`

const HeroContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 300px;
  min-height: 100%;

  @media (min-width: 600px) {
    height: 350px;
  }

  @media (min-width: 900px) {
    height: 450px;
    padding: ${props => `0 ${props.theme.size.xxl}`};
  }
`

const HeroCopy = styled.div`
  h1,
  p {
    color: #fff;
  }

  @media (min-width: 900px) {
    padding-bottom: 36px;
    width: calc(100% - 400px - 48px);
  }
`

const HeroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`

const TopContent = styled(Content)`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
  }
`

const TopContentMainColumn = styled.div`
  @media (min-width: 900px) {
    margin-right: 48px;
    width: calc(100% - 400px - 48px);
  }
`

const TopContentSidebarStickyInner = styled.div`
  width: 100%;

  @media (min-width: 900px) {
    position: relative;
    width: 400px;
    z-index: 1;
  }
`

const HeroApplyBox = styled.div`
  background-color: ${props => props.theme.color.n20};
  margin: 0 0 48px;
  padding: 24px;
  width: 100%;
  z-index: 1;

  @media (min-width: 900px) {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 36px 0 0;
    max-height: 90vh;
    overflow-y: auto;
  }
`

const TopContentSidebarColumn = styled.div`
  /* margin-top: 36px; */

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    margin-top: -411px;
    min-width: 400px;
    width: 400px;
  }
`

const TopContentSidebarStickyContainer = styled(StickyContainer)`
  flex-grow: 2;
`

const BenefitsContainer = styled.div`
  background-color: ${props => props.theme.color.n10};
  margin-top: 36px;
  padding: 36px 24px 24px;

  h2 {
    font-size: 28px;
    font-weight: 600;
    margin: 36px 0 16px;
    margin-top: 0;
    max-width: none;
    text-align: left;
    text-transform: none;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      background-image: url('/icons/fa-check.svg');
      background-repeat: no-repeat;
      background-position: left 3px;
      background-size: 21px;
      font-size: 18px;
      line-height: 1.5;
      list-style: none;
      margin: 12px 0;
      padding: 0 0 0 36px;
    }
  }

  @media (min-width: 768px) and (max-width: 899.5px), (min-width: 1024px) {
    ul {
      columns: 2;
      column-gap: ${props => props.theme.size.m};
    }
  }
`

// const VideoContent = styled(Content)`
//   @media (max-width: 899px) {
//     padding: 0;
//     width: 100%;
//   }
// `

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "landing-pages/tech-hero.jpg" }) {
      ...FullWidthImage
    }
    heroImageMobile: file(relativePath: { eq: "landing-pages/tech-hero.jpg" }) {
      ...SharpImage600
    }
  }
`

export default TechLandingPage
