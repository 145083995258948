import React from 'react'
import * as PropTypes from 'prop-types'
import { Label } from './label'

export const Select = ({
  children,
  id,
  label,
  name,
  placeholder,
  register,
  required,
  ...props
}) => {
  return (
    <React.Fragment>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <select name={name} ref={register({ required })} id={id} required={required} {...props}>
        <option value=''>{placeholder}</option>
        {children}
      </select>
    </React.Fragment>
  )
}

Select.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
}

Select.defaultProps = {
  placeholder: 'Select an option',
  required: false,
}
